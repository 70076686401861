import React, { useEffect, useState } from 'react';
import Navigation from '../components/navigation/navigation';
import { Helmet } from 'react-helmet';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import OGImage from '../images/favicon-black.png';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { navigate } from 'gatsby-link';
import { URL_API } from 'gatsby-env-variables';

const PricingGuideRazorpayPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        pricing_guide_bg: file(relativePath: { eq: "paid-account-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const url = typeof window !== 'undefined' ? window.location.origin : '';

  const bg_pricing_guide = getImage(data.pricing_guide_bg);
  const pricingGuideBg = convertToBgImage(bg_pricing_guide);
  const [currencyCode, setCurrencyCode] = useState('');
  const [annualPrice, setAnnualPrice] = useState();
  const [monthlyPrice, setMonthlyPrice] = useState();
  const [lifetimePrice, setLifetimePrice] = useState();
  const [cookies, removeCookies, setCookies] = useCookies(['loggedIn', 'currentUser']);

  const [currentUser, setCurrentUser] = useState(cookies.currentUser);

  const [subscriptionStatus, setSubscriptionStatus] = useState();

  const getData = async () => {
    const detectCurrency = await axios.get(`${URL_API}/pricing`);

    setCurrencyCode(detectCurrency.data.data.pricing.currency_symbol);
    setMonthlyPrice(detectCurrency.data.data.pricing.price_monthly);
    setAnnualPrice(detectCurrency.data.data.pricing.price_yearly);
    setLifetimePrice(detectCurrency.data.data.pricing.price_lifetime);
  };

  function setMonthly() {
    sessionStorage.setItem('subscriptionType', 'Monthly');
    sessionStorage.setItem('subscriptionAmount', monthlyPrice);
    navigate('/payment-razorpay');
  }

  function setYearly() {
    sessionStorage.setItem('subscriptionType', 'Yearly');
    sessionStorage.setItem('subscriptionAmount', annualPrice);
    navigate('/payment-razorpay');
  }

  function setLifetime() {
    sessionStorage.setItem('subscriptionType', 'Lifetime');
    sessionStorage.setItem('subscriptionAmount', lifetimePrice);
    navigate('/payment-razorpay');
  }

  useEffect(() => {
    getData();
  }, [subscriptionStatus]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${url}/pricing-guide`} />
        <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
        <meta property="og:title" content="Pricing Guide | Girar World" />
        <meta property="description" content="Girar, go on a journey" />
        <meta property="og:description" content="Girar, go on a journey" />
        <meta property="og:url" content={`${url}/pricing-guide`} />
        <meta property="og:image" content={`${url}${OGImage}`} />
        <meta name="twitter:url" content={`${url}/pricing-guide`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@WeltgeistKiran" />
        <meta name="twitter:title" content="Pricing Guide | Girar World" />
        <meta name="twitter:description" content="Girar, go on a journey" />
        <meta property="twitter:image" content={`${url}${OGImage}`} />
        <meta property="og:type" content="website" />
        <title> Pricing Guide | Girar World </title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;600;700;800;900&family=Telex&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navigation />
      <BackgroundImage
        Tag="section"
        className="bg-center"
        // Spread bgImage into BackgroundImage:F
        {...pricingGuideBg}>
        <section className="w-full pl-5 px-5 xl:pb-40 pb-20 pt-28">
          <div className="grid grid-cols-12 max-w-screen-xl mx-auto">
            <div className="w-full col-span-12 pb-10">
              <h3 className="font-rokkit font-bold mb-2 text-white uppercase text-center xl:text-5xl text-3xl">Pricing Options</h3>
            </div>
            <div className="xl:col-span-4 xl:px-5 px-2 text-center col-span-12 xl:mb-0 mb-5">
              <div className="border-white rounded-3xl border-4 px-4 mx-auto text-brown-50 text-xl xl:mt-10 mt-5 pt-8 xl:pt-9 xl:pb-7 pb-9 bg-white">
                <p className="text-brown-50 xl:mt-3 font-bold">Monthly</p>
                <h3 className=" font-bold mb-4 text-brown-50 uppercase text-center xl:text-7xl text-5xl">
                  <sup className="xl:text-4xl text-3xl">{currencyCode}</sup>
                  {monthlyPrice}
                </h3>
                <p className="text-base font-semibold font-sans">Explore the landscapes of Girar with a limitless monthly access plan.</p>

                <ul className="w-full rounded-lg mt-2 mb-3 paid-account-list">
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 text-left font-sans font-semibold text-base text-brown-50">
                        Unlimited access to stories as they are being released in real time
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 text-left font-sans font-semibold text-base text-brown-50">
                        Access to all previously written books, chapters, and installations
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans font-semibold text-base text-brown-50">
                        Private chat with the author
                      </span>
                    </div>
                  </li>
                </ul>
                <button
                  onClick={setMonthly}
                  className="uppercase px-7 rounded-md border-4 mx-auto mt-2 text-orange-150 font-rokkit text-xl xl:py-3 py-2 font-bold bg-#312D23 hover:border-orange-150 hover:shadow-inner2 hover:bg-white-rock hover:text-brown-50 transition-all duration-500">
                  Choose Plan
                </button>
              </div>
            </div>
            <div className="xl:col-span-4 xl:px-5 px-2 text-center col-span-12 ">
              <div className="relative border-#E5DCBF bg-#E5DCBF rounded-3xl border-4 px-4 mx-auto text-xl mt-10 xl:pt-8 pt-5 xl:pb-7 pb-9 text-brown-50">
                <div className="xl:h-14 xl:w-50 h-10 w-48 bg-brown-50 flex items-center justify-center rounded-md absolute xl:-top-9 -top-6 right-0 left-0 mx-auto">
                  <h3 className="text-orange-150 uppercase xl:text-xl text-base font-opensans">Popular</h3>
                </div>
                <p className="mt-4 font-bold">Annual payment</p>
                <h3 className=" font-bold mb-4 uppercase text-center xl:text-7xl text-5xl">
                  <sup className="xl:text-4xl text-3xl">{currencyCode}</sup>
                  {annualPrice}
                </h3>
                <p className="text-base font-semibold font-sans">Explore the landscapes of Girar with a limitless annual access plan.</p>
                <ul className="w-full rounded-lg mt-2 mb-3 paid-account-list">
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 text-left font-sans text-base font-semibold">
                        Unlimited access to stories as they are being released in real time
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 text-left font-sans text-base font-semibold">
                        Access to all previously written books, chapters, and installations
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans text-base font-semibold">Private chat with the author</span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans text-base font-semibold">
                        Digital authograph from the author
                      </span>
                    </div>
                  </li>
                </ul>
                <button
                  onClick={setYearly}
                  className="uppercase px-7 rounded-md border-4 mx-auto mt-2 text-orange-150 font-rokkit text-xl xl:py-3 py-2 font-bold bg-#312D23 hover:border-orange-150 hover:shadow-inner2 hover:bg-white-rock hover:text-brown-50 transition-all duration-500">
                  Choose Plan
                </button>
              </div>
            </div>
            <div className="xl:col-span-4 xl:px-5 px-2 text-center col-span-12 ">
              <div className="border-white rounded-3xl border-4 px-4 mx-auto text-brown-50 text-xl xl:mt-10 mt-11 pt-8 xl:pt-9 xl:pb-7 pb-9 bg-white">
                <p className="text-brown-50 xl:mt-3 font-bold">Lifetime</p>
                <h3 className=" font-bold mb-4 text-brown-50 uppercase text-center xl:text-7xl text-5xl">
                  <sup className="xl:text-4xl text-3xl">{currencyCode}</sup>
                  {lifetimePrice}
                </h3>
                <p className="text-base font-semibold font-sans">Explore the landscapes of Girar with a limitless access plan.</p>

                <ul className="w-full rounded-lg mt-2 mb-3 paid-account-list">
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans font-semibold text-base text-brown-50">
                        Unlimited access to stories as they are being released in real time
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans font-semibold text-base text-brown-50">
                        Access to all previously written books, chapters, and installations
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans font-semibold text-base text-brown-50">
                        Access to all previously written books, chapters, and installations
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans font-semibold text-base text-brown-50">
                        Private chat with the author
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans font-semibold text-base text-brown-50">
                        A physical copy of Bhat's previously published novel, we of the forsaken world, autographed and delivered to any
                        address in the world.
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="w-fill flex p-3 pl-3">
                      <StaticImage
                        src={'../images/brown-check.png'}
                        formats={['auto', 'webp', 'avif']}
                        width={20}
                        alt="continue reading book"
                        className="flex-none w-6 h-full"
                        placeholder="blurred"
                      />
                      <span className="ml-2 xl:truncate text-left font-sans font-semibold text-base text-brown-50">
                        A digital autograph and epigraph to be dedicated to the subscriber
                      </span>
                    </div>
                  </li>
                </ul>
                <button
                  onClick={setLifetime}
                  className="uppercase px-7 rounded-md border-4 mx-auto mt-2 text-orange-150 font-rokkit text-xl xl:py-3 py-2 font-bold bg-#312D23 hover:border-orange-150 hover:shadow-inner2 hover:bg-white-rock hover:text-brown-50 transition-all duration-500">
                  Choose Plan
                </button>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </>
  );
};

export default PricingGuideRazorpayPage;
